<template>
    <div
        :class="$config('settings.layoutColors.containerBackground')"
        class="fill-height align-start"
    >
        <template>
            <v-main>
                <v-container fluid>
                    <v-row>
                        <v-col cols="12">
                            <transition name="slide">
                                <router-view></router-view>
                            </transition>
                        </v-col>
                    </v-row>
                </v-container>
            </v-main>
            <app-footer></app-footer>
            <psi-snackbar></psi-snackbar>
        </template>
    </div>
</template>

<script>
export default {
    name: "unauth-layout",
    components: {
        AppFooter: () => import("./AppFooter"),
    },
    data: () => ({
        drawer: null,
    }),
    computed: {},
    created() {},
    mounted() {},
    methods: {},
};
</script>

<style>
.v-navigation-drawer__border {
    display: none;
}
.slide-leave-active {
    transition: 0.1s;
    opacity: 0;
}
.slide-enter-active {
    transition: 0.9s;
    opacity: 1;
}
.slide-enter {
    transform: translateY(100%);
}
.slide-leave-to {
    transform: translateY(50%);
}
</style>

